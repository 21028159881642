<template>
  <div>

    <vuestic-widget v-if="checklists.length > 0">
      <div v-for="checklist in checklists" :key="checklist.id">
        <vuestic-card>
          <div class="va-row">
            <div class="flex xs12 md9">
              <h4>{{checklist.nome}}</h4>
              <p>Descrição</p>
            </div>
            <div class="flex xs12 md3">
              <br>
              <button
                @click="responderChecklist(checklist)"
                class="btn btn-primary btn-micro"
              >{{'Responder' | translate}}</button>
            </div>
          </div>
        </vuestic-card>
        <br><br>
      </div>
    </vuestic-widget>
  </div>
</template>

<script>
export default {
  name: 'lista-checklist-user',
  data() {
    return {
      acesso: [],
      show: true,
      user: JSON.parse(localStorage.getItem('user')),
      empresa: JSON.parse(localStorage.getItem('empresaSelecionada'))[0] || JSON.parse(localStorage.getItem('empresaSelecionada')),
      checklists: [],
    };
  },
  created() {
    this.getAcesso();
  },
  methods: {
    getChecklist() {
      this.$axios.get('/api/checklist.json').then((res) => {
        this.checklists = (res.data).filter(this.filtrarChecklistPorAcesso);
      });
    },
    async responderChecklist(checklist) {
      const acessoEscolhido = await this.acesso.find(d => d.checklist === checklist.id);
      this.$router.push({ name: 'responder-checklist', params: { id: checklist.id, acesso: acessoEscolhido.id } });
    },
    getAcesso() {
      this.$axios.get('/api/acesso.json').then((res) => {
        this.acesso = (res.data).filter(this.filtrarAcessoPorFuncionario);
        this.getChecklist();
      });
    },
    filtrarAcessoPorFuncionario(acesso) {
      // eslint-disable-next-line eqeqeq
      return (acesso.funcionario == this.user.funcionario.id) && (acesso.empresa == this.empresa);
    },
    fazerListaDeTodosOsValorDeUmObjeto(objetos, valor) {
      if (objetos.length < 1) return [];
      const array = new Set();
      objetos.forEach(objeto => array.add(objeto[valor]));
      return array;
    },
    filtrarChecklistPorAcesso(checklists) {
      const test = this.fazerListaDeTodosOsValorDeUmObjeto(this.acesso, 'checklist');
      return test.has(checklists.id);
    },
  },
};
</script>

<style lang="scss">
.collapse-page {
  &__content {
    padding: 2rem;
    &__title {
      font-size: 1.375rem;
      font-weight: bold;
    };
  }
}
</style>
